import logo from './logo.svg';
import './App.css';
import Home from './screens/Home.tsx'
import Terms from './screens/Terms.tsx';
import Privacy from './screens/Privacy.tsx';
import Disclaimer from './screens/Disclaimer.tsx';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop.tsx';
import Stark from './screens/Stark.tsx';
import Storm from './screens/Storm.tsx';
import Contact from './screens/Contact.tsx';
import Leistung from './screens/Leistung.tsx';

function App() {
  return (
    <Router>
      <ScrollToTop />
<Routes>
<Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/stark" element={<Stark />} />
        <Route path="/storm" element={<Storm />} />
        <Route path="/leistung" element={<Leistung />} />
        <Route path="/contact" element={<Contact />} />
        </Routes>
    </Router>
  );
}

export default App;
