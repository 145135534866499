import React, { useState } from 'react';
import ImgCity from "../Assets/city-line.png"
import Logo from "../Assets/logo.png";

import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
const Footer = () => {

    return (
        <>
            <div className='menuTop padding-topCstm1'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-3 col-sm-6 footerLinks mobHide'>
                            {/* <h2>Quick Links</h2> */}
                            <Link to="/"><img src={Logo} alt="banner" className="logo" /></Link>
                            <ul>
                                <li>We are dedicated to redefining the future of mobility with a relentless focus on sustainability and innovation. </li>
                            </ul>
                        </div>
                        <div className='col-md-3 col-sm-6 footerLinks'>
                            <h2>Our Products</h2>
                            <ul>
                                <li><Link to="/stark">&rarr; Acutok stark</Link></li>
                                <li><Link to="/storm">&rarr; Acutok storm</Link></li>
                                <li><Link to="/">&rarr; Acutok leistung</Link></li>
                                <li><Link to="/">&rarr; Acutok glatt</Link></li>
                                <li><Link to="/">&rarr; Acutok licht</Link></li>
                            </ul>
                        </div>
                        <div className='col-md-3 col-sm-6 footerLinks'>
                            <h2>Quick Links</h2>
                            <ul>
                                <li><Link to="/disclaimer">&rarr; Disclaimer</Link></li>
                                <li><Link to="/privacy">&rarr; Privacy Policy</Link></li>
                                <li><Link to="/terms">&rarr; Terms & Conditions</Link></li>
                                {/* <li><Link to="/">&rarr; Service Stations</Link></li> */}
                                <li><Link to="/contact">&rarr; Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className='col-md-3 col-sm-12 footerLinks'>
                            <h2>Connect With Us</h2>
                            <ul>
                                <li>Plot No. 36 & 37. Sr No. 101/1/1(P, Banglore Highway, near Mercedes Showroom, Baner, Mumbai, Pune, Maharashtra 411045</li>
                                    <li>&rarr; Email: info@acutok.com</li>
                                    <li>&rarr; Email: sales@acutok.com</li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <img src={ImgCity} className='cityImg' />
            </div>
            <div className='menuBottom'>
                <div className='col-12 footerBottom'>
                    <p>Copyright @2024 | All rights reserved</p>
                </div>
            </div>
        </>
    )
}
export default Footer